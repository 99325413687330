.login-form{
    width:450px;
    margin: auto;
    margin-top: 5%;
}

.login-form button{
    width: 100%;
}

@media (max-width: 866px) { 
    .login-form{
        width:90%;
    }
}

/* Show and hide password */
.pwd-container {
    position: relative;
}
  
.pwd-container .icon {
    cursor: pointer;
    position: absolute;
    width: 20px;
    right: 8px;
    top: 4px;
}
