@import './variables.scss';

html{
  background-color: #fff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Arial, Helvetica, sans-serif;
  min-width: 1000px;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom_button{
  background-color: #009cee;
  border-radius: 4px;
  border:none;
  color:white;
  height: 40px;
}

.form-header{
  font-size: 20px;
  color: $theme-color-light;
  font-weight: bold;
}

.input-label{
  font-size: 16px;
  color: $theme-color-light;
  font-weight: 500;
  padding: 0px 5px;
}

.float-right{
  float: right;
}

.cursor-pointer{
  cursor: pointer;
}

.float-left{
  float: left;
}

.mb-3{
  position: relative;
}

.mb-3 .show-password-icon{
  cursor: pointer;
  position: absolute;
  width: 20px;
  right: 8px;
  top: 0px;
}

.content{
  flex:1;
  overflow: scroll;
}

.logged-in-container{
  margin: 20px 40px;
}

.info-list-div{
  width: 580px;
  margin: auto;
}

.info-list-box-div{
  background-color: #fff;
  padding: 30px 40px;
  border-radius: 8px;
  border: solid 1px #a3bbca;
  margin-bottom: 30px;
}

.info-list-box-div_header{
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 16px;
  color: $theme-color-light;
}

.info-table-label{
  font-weight: 500;
  color:#36454f;
  font-size: 16px;
}

.info-table-info{
  text-align: right;
  color: #36454f;
}

.approval-button{
  border-radius: 50%;
  margin-top: 20px;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  background-color: #fff;
  border: 1px solid rgb(190, 188, 188);
}

.desision-button{
  border:none;
  width: 26%;
  height: 50px;
  color:white;
  font-weight: 700;
  border-radius: 7px;
}

.error-label {
  color:red !important;
  font-size: 14px;
  margin: 5px;
}

.invisible-button{
  background: none;
  border: none;
}

.span-button{
  cursor: pointer;
}

.profile-pic{
  border-radius: 50%;
  max-width:115px;
  max-height:48px;
  width: auto;
  height: auto;
}

.btn-primary{
  background-color: $theme-color-dark;
  border-color: $theme-color-dark !important;
 
  &:after,&:focus,&:visited, &:hover{
    color: #fff !important;
    background-color: $theme-color-dark !important;
  }
}

.btn-outline-primary{
  color: $theme-color-dark !important;
  border: 1px solid $theme-color-dark  !important;

  &:after,&:focus,&:visited, &:hover{
    color: #fff !important;
    background-color: $theme-color-dark !important;
  }
}
