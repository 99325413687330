@import './variables.scss';


.recruiter-table{
    background-color: #fff;
    width: 100%;
    border: $custom-border-style;
    border-radius: 8px;
}

.recruiter-table th{
    border-bottom: $custom-border-style;
    padding: 15px;
    color:#445566;
    padding-left: 20px;
}

.recruiter-table td{
    padding:12px;
    border-bottom: $custom-border-style;
    font-size: 16px;
    padding-left: 20px;
}

.font-14{
    font-size: 14px !important;
}

.pagination-div{
    padding: 10px;
    border: $custom-border-style;
    background-color: #fff;
    margin-top: 10px;
    // text-align: center;
}

.td-link{
    color: inherit;
    padding: 4px;
}
  
.align-left-col{
    text-align: left;
}

.align-right-col{
    text-align: right;
}

@keyframes top-bottom-animation {
    0% { max-height:0}
    100% { max-height:100px}
}

.history-table-div{
    font-size: 12px;
    table{
        width: 90%;
    }
    margin: 20px;
    max-height: 100px;
    overflow-y: scroll;
    animation: top-bottom-animation 1s;    
}
  

  