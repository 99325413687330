@import './variables.scss';


@keyframes alert-animation {
    0% {top:0}
    50% {top:50px}
    70% {top:10px}
    100% {top:30px}
}

@mixin system-message($color){
    border-radius: 8px;
    background-color: $color;
    height:50px;
    z-index: 999;
    border:none;
    color: #fff;
    text-align: center;
    line-height: 3;
    top:30px;
    position: fixed;
    width: 700px;
    left: calc( 50vw - 350px );
    animation: alert-animation 1s;
}

.success-system-message{
    @include system-message($success-message-color);
}

.error-system-message{
    @include system-message($error-message-color);
}