/* create a modal */
.custom_modal {
    position: absolute;
    z-index: 1; 
    top: 0;
    width: 100%;
    height: 100%; 
    overflow: auto; 
  
  }
  
  .DayPicker {
    font-size: 12px !important;
  }