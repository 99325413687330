.custom-modal-content{
    border-radius: 18px !important;
    margin-top: 20%;
    padding: 10px;
}

.custom-modal-ok-button{
    background-color:#249afa;
    border:none;
    width:25%;
    color:white;
    border-radius:4px;
    font-weight:500;
    height:40px;
  }
  
.custom-modal-reset-button{
    background:none;
    border:none;
    width:25%;
    color:#839eaf;
    border-radius:4px;
    font-weight:500;
    height:40px;
}

.custom-modal-input-field{
    border: solid 1px #aabbcc;
    border-radius:18px;
    padding:5px;
    padding-left:12px;
    padding-right:12px;
    margin-right:10px;
}

.timesheet.custom-modal-input-field{

    padding-left:5px;
    padding-right:5px;
   
}