@import './variables.scss';

@keyframes side-nav-animation {
  0% { margin-left: -240px;}
  100% { margin-left: 0px;}
}

.left-side-nav{
  background-color: $theme-color-dark;
  min-width:240px;
  max-width:240px;
  height: 100vh;
  flex: 1;
  position: sticky;
  top: 0;
  animation: side-nav-animation 1s;
}

.left-nav-main-category{
  margin: 20px;
  border:none;
}

.left-nav-main-category:hover{
  background-color: $theme-color-light;
  border-radius: 5px;
}

.left-nav-main-category .name{
  color:white;
  margin-left: 10px;
  font-size: 14px;
}

.left-nav-upper-category{
  border-bottom: 2px solid white;
  width: 80%;
  margin: auto;
}

.left-nav-lower-category .name{
  color:white;
  margin-left: 10px;
  font-size: 14px;
  margin: auto;
  margin-left: 40px;
  width: 80%;
  margin-top: 20px;
}