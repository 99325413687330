@import './variables.scss';

.job-listing-table {
    width:50%;
    max-width: 600px;
    margin: auto;
    td{
        padding: 7px;
    }
    .form-header{
        padding-top: 40px;
    }
    .table-value{
        color: $theme-color-light;
    }
}